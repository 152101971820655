export const USER_TYPES = { sender: 'SENDER', recipient: 'RECIPIENT', senderShopify: 'SENDER_SHOPIFY' };

export const ERR_MSGS = {
  qrCodeinUse: 'QR code already used.',
  qrCodeInvalid: 'QR code is invalid.',
  projectNotAuthorized: 'Project not authorized.',
};

export const PRJ_STATUS = { created: 'CREATED', normalized: 'NORMALIZED', viewed: 'VIEWED', reset: 'RESET' };

export const COOKIE_KEYS = { refreshToken: 'refreshToken', bearerToken: 'bearerToken', phone: 'phone' };

export const QR_TYPES = { physical: 'PHYSICAL', phone: 'PHONE' };

export const ROUTES = {
  senderLanding: '/sender-landing',
  senderUploadPreview: '/sender-upload-preview',
  senderOptionsThankyou: {
    parent: '/sender-options-thankyou',
    children: { options: 'options', thankyou: 'thankyou' },
  },
  recipientSecured: '/recipient-secured', //not currently in use
  recipientLanding: '/recipient-landing',
  recipientReply: {
    parent: '/recipient-reply',
    children: { options: 'options', video: 'video', message: 'message' },
  },
  recipientUploadPreview: '/recipient-upload-preview',
  recipientOptionsThankyou: '/recipient-options-thankyou',
  qrCodeNotFound: '/qrcode-not-found',
  qrCodeUsed: '/qrcode-used',
  submitSenderPhone: '/submit-sender-phone',
  videoProcessing: '/video-processing',
  viewVideo: '/view/:qrcode',
  desktop: '/desktop',
  homepage: '/homepage',
  reset: '/reset',
  uploadShopify: '/video-upload',
  senderShopifyThankyou: '/sender-shopify-thankyou',
  demo: '/ppq-imng-pvh',
};

export const publicRoutesRegExp = [
  '^/$', // '/' root used for homepage and ?qrcode= sender/recipient flow
  '^/view/[A-Za-z0-9]+$', // '/view/:qrcode' used for sms url and social share
  '^/reset$', // '/reset' used for resetting codes
  '^/video-upload$', // '/reset' used for resetting codes
  '^/ppq-imng-pvh$', // '/demo' used for demo qr codes
];

export const TYPE_VIDEO = 'VIDEO';
export const TYPE_PHOTO = 'IMAGE';
