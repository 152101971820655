import create from 'zustand';
import { devtools } from 'zustand/middleware';
import actionsAsync from './actions-async';
import appTemplates from '@src/app-templates';

//set(state,clearState,actionName) - actioName is used for devtools logging
const store = (set, get) => ({
  emojiArray: [],
  projectEmojis: [],
  isMobile:
    /Android|webOS|iPhone|iPad|iPod/i.test(navigator.userAgent) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1), //fix for ipad defaults to desktop mode (userAgent is Macintosh)
  abortController: undefined,
  resetAbortController: () => set({ abortController: undefined }, false, 'resetAbortController'),
  userId: undefined,
  setUserId: userId => set({ userId }, false, 'setUserId'),
  userName: undefined,
  setUserName: userName => set({ userName }, false, 'setUserName'),
  resetToken: '',
  setResetToken: resetToken => set({ resetToken }, false, 'setResetToken'),
  projectStatus: undefined,
  qrType: undefined,
  videoUrl: '',
  videoThumbUrl: undefined,
  projectId: undefined,
  projectsReplyId: undefined,
  acceptReply: process.env.ACCEPT_REPLY_DEFAULT === 'true',
  setAcceptReply: acceptReply => set({ acceptReply }, false, 'setAcceptReply'),
  phone: { intValue: '', nationalValue: '', country: null },
  setPhone: phone => set({ phone }, false, 'setPhone'),
  smsCode: undefined,
  setSmsCode: smsCode => set({ smsCode }, false, 'setSmsCode'),
  name: undefined,
  setName: name => set({ name: name || undefined }, false, 'setName'),
  isSecured: process.env.IS_SECURED_DEFAULT === 'true',
  setIsSecured: isSecured => set({ isSecured }, false, 'setIsSecured'),
  errors: null,
  setErrors: (error, actionName) => {
    let errorsObj = null;
    if (error && error.response && error.response.data) {
      errorsObj = error.response.data.errors || error.response.data;
      if (errorsObj && typeof errorsObj.userMessage === 'object') {
        errorsObj.userMessage = Object.values(errorsObj.userMessage)[0];
      }
    }
    set({ errors: errorsObj }, false, actionName || 'setErrors');
  },
  isLoading: false,
  setIsLoading: isLoading => set({ isLoading }, false, 'setIsLoading'),
  validationCode: null,
  setValidationCode: validationCode => set({ validationCode }, false, 'setValidationCode'),
  userType: 'SENDER',
  setUserType: userType => set({ userType }, false, 'setUserType'),
  qrCode: null,
  setQrCode: qrCode => set({ qrCode }, false, 'setQrCode'),
  videoDevices: [],
  getVideoDevices: () => {
    navigator.mediaDevices
      .enumerateDevices()
      .then(devices => set({ videoDevices: devices.filter(d => d.kind === 'videoinput') }));
  },
  activeCamera: 'front-camera',
  setActiveCamera: activeCamera => set({ activeCamera }, false, 'setActiveCamera'),
  recordingBlob: null,
  setRecordingBlob: recordingBlob => set({ recordingBlob }, false, 'setRecordingBlob'),
  isUploadingFile: null,
  setIsUploadingFile: isUploadingFile => set({ isUploadingFile }, false, 'setisUploadingFile'),
  modalError: { isOpen: false, component: null, onClose: null },
  setModalError: ({ isOpen, component, onClose, userMessage }) =>
    set({ modalError: { isOpen, component, onClose, userMessage } }, false, 'setModalError'),
  replyText: null,
  usersProfiles: null,
  isSenderPhone: false,
  shopifyData: {
    oid: null,
    pid: null,
    mediaId: null,
  },
  setShopifyData: shopifyData => set({ shopifyData }, false, 'setShopifyData'),
  slideId: null,
  mediaId: null,
  repliesVideosUrls: null,

  appTemplate: appTemplates['default'],
  setAppTemplate: appTemplate => {
    if (!appTemplate) return;
    const templateData = appTemplates[appTemplate.slug];
    set(
      {
        appTemplate: {
          ...appTemplate,
          ...templateData,
          logoImageUrl: appTemplate?.mediaURL || templateData?.logoImageUrl,
        },
      },
      false,
      'setAppTemplate'
    );
  },
  hasEventAttached: false,
  eventToken: null,
  ...actionsAsync(set, get),
  uploadStartTimestamp: null,
  setUploadStartTimestamp: uploadStartTimestamp => set({ uploadStartTimestamp }, false, 'setUploadStartTimestamp'),
  uploadEndTimestamp: null,
  setUploadEndTimestamp: uploadEndTimestamp => set({ uploadEndTimestamp }, false, 'setUploadEndTimestamp'),
});

export const useStore = create(process.env.NODE_ENV === 'development' ? devtools(store) : store);
