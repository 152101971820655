import axios from 'axios';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import { COOKIE_KEYS, PRJ_STATUS } from '@src/constants';
import config from '@src/config';
import { MultiPartUploader } from '@src/utils/multiPartUploader';

axios.defaults.baseURL = process.env.API_URL;

const actionsAsync = (set, get) => ({
  postProject: async ({ isReply = false, code = undefined } = {}) => {
    get().setErrors(null);
    get().setIsLoading(true);
    try {
      let abortController = get().abortController;
      if (!abortController) {
        abortController = new AbortController();
        set({ abortController }, false, 'postProject/setAbortController');
      }
      const { data } = await axios.post(
        `/projects`,
        { qrCode: get().qrCode, isReply },
        { signal: abortController.signal }
      );
      const { status, id, qrType, appTemplate, hasEventAttached, eventToken } = data;
      if (!isReply) {
        set({ projectStatus: status, projectId: id, qrType, hasEventAttached, eventToken }, false, 'postProject/set');
        get().setAppTemplate(appTemplate);
      } else {
        set({ projectsReplyId: id }, false, 'postProject/setProjectsIdReply');
      }
    } catch (error) {
      get().setErrors(error, 'postProject/setErrors');
    } finally {
      get().setIsLoading(false);
    }
  },

  getProject: async ({ code = undefined } = {}) => {
    get().setErrors(null);
    get().setIsLoading(true);
    try {
      let searchParams = new URLSearchParams();
      if (get().isSecured) {
        searchParams.append('phoneNumber', get().phone);
        searchParams.append('smsCode', get().smsCode || '0000');
      }
      if (code) {
        set({ isSenderPhone: true }, false, 'getProject/setIsRecipientPhone');
        searchParams.append('code', code);
      }
      const response = await axios.get(`/projects/${get().qrCode}?${searchParams.toString()}`);
      const {
        status,
        qrType,
        finalMediaUrl,
        previewMediaUrl,
        acceptReply,
        id,
        isSecured,
        projectsHasFeedbacks,
        replyText,
        usersProfiles,
        repliesVideosUrls,
        appTemplate,
        hasEventAttached,
        eventToken,
        finalMediaType,
        senderName,
				formattedCustomNote
      } = response.data;

      // sender flow, on rescan qrcode, status would be created
      set(
        { projectStatus: status, projectId: id, qrType, hasEventAttached, eventToken },
        false,
        'getProject/setProjectStatus'
      );
      get().setAppTemplate(appTemplate);

      //recipient flow
      if (status === PRJ_STATUS.normalized || status === PRJ_STATUS.viewed) {
        set(
          {
            videoUrl: finalMediaUrl || previewMediaUrl,
            acceptReply,
            isSecured,
            projectEmojis: projectsHasFeedbacks,
            replyText,
            usersProfiles,
            repliesVideosUrls,
            finalMediaType,
            senderName,
            formattedCustomNote
          },
          false,
          'getProject/set'
        );
      }
    } catch (error) {
      if (error.response && error.response.status === 303 && error.response.data) {
        const { thumbnailMediaUrl, title } = error.response.data;
        set({ isSecured: true, videoThumbUrl: thumbnailMediaUrl, name: title }, false, 'getProject-secured/set');

        return Promise.resolve();
      } else {
        get().setErrors(error, 'getProject/setErrors');

        return Promise.reject();
      }
    } finally {
      get().setIsLoading(false);
    }
  },

  getProjectReply: async replyId => {
    get().setErrors(null);
    get().setIsLoading(true);
    try {
      const response = await axios.get(`/projects/${get().qrCode}/replies/${replyId}`);
      const { status, finalMediaUrl, previewMediaUrl, appTemplate } = response.data;
      set({ videoUrl: finalMediaUrl || previewMediaUrl, projectStatus: status }, false, 'getProjectReply/set');
      get().setAppTemplate(appTemplate);
    } catch (error) {
      if (error.response && error.response.status === 303 && error.response.data) {
        const { thumbnailMediaUrl, title } = error.response.data;
        set({ isSecured: true, videoThumbUrl: thumbnailMediaUrl, name: title }, false, 'getProjectReply-secured/set');
      } else {
        get().setErrors(error, 'getProject/setErrors');

        return Promise.reject();
      }
    } finally {
      get().setIsLoading(false);
    }
  },

  uploadVideo: async ({ isReply = false } = {}) => {
    get().setErrors(null);
    get().setIsLoading(true);

    const {
      validationCode,
      qrCode,
      recordingBlob: blob,
      abortController = new AbortController(),
      projectsReplyId,
    } = get();
    const options = {
      validationCode,
      blob,
      abortController,
      fileName: `${qrCode}.mp4`,
      uploadURLs: {
        init: `${process.env.API_URL}/multipart-upload/init`,
        create: `${process.env.API_URL}/multipart-upload/create`,
        part: `${process.env.API_URL}/multipart-upload/part`,
        complete: `${process.env.API_URL}/multipart-upload/complete`,
        abort: `${process.env.API_URL}/multipart-upload/abort`,
      },
      initPayload: {
        type: 'VIDEO',
        category: 'SLIDE',
        qrCode,
        replyId: projectsReplyId || null,
        validationCode,
      },
      onComplete: async ({ id }) => {
        get().setUploadEndTimestamp(+new Date());

        try {
          const { data: dataSlide } = await axios.post(
            `/projects/${qrCode}/slides`,
            {
              slideType: 'VIDEO',
              mediaId: id,
              replyId: isReply ? projectsReplyId : undefined,
            },
            {
              signal: abortController.signal,
            }
          );

          set({ slideId: dataSlide.id, mediaId: id }, false, 'uploadVideo/setSlideId');
        } catch (error) {
          get().setErrors(error, 'uploadVideo/setErrors');

          return Promise.reject(error);
        } finally {
          get().setIsLoading(false);
        }
      },
    };

    // Initialize.
    get().setUploadStartTimestamp(+new Date());
    get().setUploadEndTimestamp(null);
    new MultiPartUploader(options).start();
  },

  uploadVideoShopify: async () => {
    get().setErrors(null);
    get().setIsLoading(true);

    const { validationCode, shopifyData, recordingBlob: blob, abortController = new AbortController() } = get();
    let inputType = 'video';
    let fileExtention = blob.name.split('.')?.[1] ?? 'mp4';

    if (blob.type.indexOf('image/') !== -1) {
      inputType = 'image';
    }

    const options = {
      validationCode,
      blob,
      abortController,
      fileName: `${inputType}-${shopifyData.pid}.${fileExtention}`,
      uploadURLs: {
        init: `${process.env.API_URL}/multipart-upload/init`,
        create: `${process.env.API_URL}/multipart-upload/create`,
        part: `${process.env.API_URL}/multipart-upload/part`,
        complete: `${process.env.API_URL}/multipart-upload/complete`,
        abort: `${process.env.API_URL}/multipart-upload/abort`,
      },
      initPayload: {
        type: inputType.toUpperCase(),
        category: 'SLIDE',
        oid: shopifyData?.oid,
        pid: shopifyData?.pid,
        validationCode,
      },
      onComplete: async ({ id }) => {
        try {
          set({ shopifyData: { ...shopifyData, mediaId: id } }, false, 'uploadVideoShopify/setShopifyData/mediaId');
        } catch (error) {
          get().setErrors(error, 'uploadVideo/setErrors');

          return Promise.reject(error);
        } finally {
          get().setIsLoading(false);
        }
      },
    };

    // Initialize.
    get().setUploadStartTimestamp(+new Date());
    get().setUploadEndTimestamp(null);
    new MultiPartUploader(options).start();
  },

  patchAttachMediaOrder: async () => {
    get().setErrors(null);
    get().setIsLoading(true);
    const {
      shopifyData: { oid, pid, mediaId },
    } = get();

    try {
      await axios.patch(`${process.env.API_MEDIA_GEN_URL}/orders/${oid}/products/${pid}/media`, { mediaId });
    } catch (error) {
      get().setErrors(error, 'uploadVideoShopify/setErrors');

      return Promise.reject(error);
    } finally {
      get().setIsLoading(false);
    }
  },

  getIsPhoneRegistered: async () => {
    get().setErrors(null);
    get().setIsLoading(true);
    try {
      const { data } = await axios.get(`/users/validate-username/${encodeURIComponent(get().phone.intValue)}`);
      const { id, username } = data;
      set({ userId: id, userName: username }, false, 'getIsPhoneRegistered/set');

      return true;
    } catch (error) {
      get().setErrors(error, 'getIsPhoneRegistered/setErrors');

      return false;
    } finally {
      get().setIsLoading(false);
    }
  },

  postRegisterPhone: async () => {
    get().setErrors(null);
    get().setIsLoading(true);
    try {
      const { data } = await axios.post(`users/registration/phone-number`, {
        phoneNumber: get().phone.intValue,
      });
      const { id, username } = data;
      set({ userId: id, userName: username }, false, 'postRegisterPhone/set');
    } catch (error) {
      get().setErrors(error, 'postRegisterPhone/setErrors');

      return Promise.reject(error);
    } finally {
      get().setIsLoading(false);
    }
  },

  postAuthenticateToken: async () => {
    get().setErrors(null);
    get().setIsLoading(true);
    try {
      const { data: dataAuth } = await axios.post(`/authentication`, { username: get().userName });
      const { id, username, accessToken } = dataAuth;
      set({ userId: id }, false, 'postAuthenticateToken/setUserID');

      const { data: dataTokens } = await axios.post(`/token`, { username, accessToken });
      const { refreshToken, bearerToken } = dataTokens;
      if (bearerToken) {
        const { exp: expBearer } = jwtDecode(bearerToken);
        Cookies.set(COOKIE_KEYS.bearerToken, bearerToken, {
          expires: new Date(expBearer * 1000),
          sameSite: 'strict',
          secure: true,
        });
        Cookies.set(COOKIE_KEYS.phone, JSON.stringify(get().phone), {
          expires: new Date(expBearer * 1000),
          sameSite: 'strict',
          secure: true,
        });
      }
      if (refreshToken) {
        Cookies.set(COOKIE_KEYS.refreshToken, refreshToken, {
          expires: new Date(Date.now() + config.refreshTokenCookieExpiresIn),
          sameSite: 'strict',
          secure: true,
        });
      }
    } catch (error) {
      get().setErrors(error, 'postAuthenticateToken/setErrors');

      return Promise.reject(error);
    } finally {
      get().setIsLoading(false);
    }
  },

  putProject: async ({ acceptReply = false, isSecured = false }) => {
    get().setErrors(null);
    get().setIsLoading(true);
    const { userId, name } = get();
    try {
      await axios.put(`/projects/${get().qrCode}`, {
        userId,
        acceptReply,
        isSecured,
        name,
      });
    } catch (error) {
      get().setErrors(error, 'putProject/setErrors');

      return Promise.reject(error);
    } finally {
      get().setIsLoading(false);
    }
  },

  patchProjectSlides: async ({ status = 'ACTIVE', isReply = false }) => {
    get().setErrors(null);
    get().setIsLoading(true);
    const { projectId, slideId, projectsReplyId } = get();
    try {
      await axios.patch(`/projects/${isReply ? projectsReplyId : projectId}/slides/${slideId}/status`, {
        status,
      });
    } catch (error) {
      get().setErrors(error, 'patchProjectSlides/setErrors');

      return Promise.reject(error);
    } finally {
      get().setIsLoading(false);
    }
  },

  postSMSValidation: async () => {
    get().setErrors(null);
    get().setIsLoading(true);
    try {
      await axios.post(`/sms/validate-secured-project`, {
        phoneNumber: get().phone.intValue,
        qrCode: get().qrCode,
      });
    } catch (error) {
      get().setErrors(error, 'postSMSValidation/setErrors');

      return Promise.reject(error);
    } finally {
      get().setIsLoading(false);
    }
  },

  submitSenderPhone: async () => {
    get().setErrors(null);
    get().setIsLoading(true);
    try {
      await axios.post(`/sms/send-project-by-phone`, {
        phoneNumber: get().phone.intValue,
        qrCode: get().qrCode,
      });
    } catch (error) {
      get().setErrors(error, 'submitSenderPhone/setErrors');

      return Promise.reject(error);
    } finally {
      get().setIsLoading(false);
    }
  },

  postGetDownload: async (forceDownload = true) => {
    get().setErrors(null);
    get().setIsLoading(true);
    try {
      return await axios.post(
        `/projects/${get().qrCode}/download`,
        {
          forceDownload,
        },
        { responseType: 'blob' }
      );
    } catch (error) {
      get().setErrors(error, 'postGetDownloadLink/setErrors');
      return Promise.reject(error);
    } finally {
      get().setIsLoading(false);
    }
  },

  getFeedbacks: async () => {
    get().setErrors(null);
    get().setIsLoading(true);
    try {
      const { data: emojiArray } = await axios.get(`/feedbacks?status=ACTIVE&type=EMOJI&sort=id&offset=1&limit=20`);
      set({ emojiArray }, false, 'getFeedbacks/setEmojiArray');
    } catch (error) {
      get().setErrors(error, 'getFeedbacks/setErrors');
    } finally {
      get().setIsLoading(false);
    }
  },

  patchFeedbacks: async id => {
    get().setErrors(null);
    get().setIsLoading(true);
    try {
      await axios.patch(`/feedbacks`, { qrCode: get().qrCode, feedbackId: id, replyId: undefined });
    } catch (error) {
      get().setErrors(error, 'patchFeedbacks/setErrors');

      return Promise.reject();
    } finally {
      get().setIsLoading(false);
    }
  },

  postContactSales: async data => {
    get().setErrors(null);
    get().setIsLoading(true);
    try {
      await axios.post(`contact`, { ...data, forceSend: true });
    } catch (error) {
      get().setErrors(error, 'postContactSales/setErrors');

      return Promise.reject(error);
    } finally {
      get().setIsLoading(false);
    }
  },

  restQrCode: async ({ resetToken, qrCode, shortUrl }) => {
    get().setErrors(null);
    get().setIsLoading(true);
    try {
      if (qrCode) {
        await axios.post(`/qr-codes/reset`, { resetToken, qrCode });
      }
      if (shortUrl) {
        await axios.post(`/qr-codes/reset`, { resetToken, shortUrl });
      }
    } catch (error) {
      get().setErrors(error, 'postContactSales/setErrors');
      return Promise.reject(error);
    } finally {
      get().setIsLoading(false);
    }
  },

  sendRepplyMessage: async replyText => {
    get().setErrors(null);
    get().setIsLoading(true);
    const { projectId } = get();
    try {
      await axios.post(`/projects/text_replies`, { replyText, projectId });
    } catch (error) {
      get().setErrors(error, 'sendRepplyMessage/setErrors');
      return Promise.reject(error);
    } finally {
      get().setIsLoading(false);
    }
  },

  getDemoQrCode: async () => {
    get().setErrors(null);
    get().setIsLoading(true);
    try {
      const { data } = await axios.get(`/demo-qr-code`);
      await axios.patch(`/demo-qr-codes/${data.code}/is_locked`, { isLocked: true });
      return data.code;
    } catch (error) {
      get().setErrors(error, 'getDemoQrCode/setErrors');
      return Promise.reject(error);
    } finally {
      get().setIsLoading(false);
    }
  },

  getCheckAttachedMedia: async ({ oid, pid }) => {
    get().setErrors(null);
    get().setIsLoading(true);
    try {
      const { data } = await axios.get(`/orders/check-attached-media`, { params: { oid, pid } });
      const { isAttached, isUploaded, appTemplate } = data;
      if (appTemplate?.appTheme) {
        const { appTheme, mediaUrl } = appTemplate;
        get().setAppTemplate({ slug: appTheme, mediaUrl });
      }
      set({ isAttached, isUploaded }, false, 'getCheckAttachedMedia/set');
      return data;
    } catch (error) {
      get().setErrors(error, 'getCheckAttachedMedia/setErrors');
      return Promise.reject(error);
    } finally {
      get().setIsLoading(false);
    }
  },

  postProjectsBulk: async () => {
    get().setErrors(null);
    get().setIsLoading(true);
    const { qrCode, mediaId, eventToken } = get();
    try {
      await axios.post(`/projects/bulk`, { qrCode, mediaId, eventToken });
    } catch (error) {
      get().setErrors(error, 'postProjectsBulk/setErrors');
      return Promise.reject(error);
    } finally {
      get().setIsLoading(false);
    }
  },

  validateMediaFile: async file => {
    try {
      get().setIsLoading(true);
      get().setValidationCode(null);

      const inputType = file.type.split('/')?.[0]?.toUpperCase();
      const checkFile = await get().getFileFromBlob(file);
      const result = await axios.post(
        `/media/slide-media-validation?type=${inputType}&category=SLIDE`,
        { file: checkFile },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      get().setValidationCode(result?.data?.validationCode);

      return result;
    } catch (error) {
      return Promise.reject(error);
    } finally {
      get().setIsLoading(false);
    }
  },
  getFileFromBlob: async file => {
    let audioFile = file;
    if (typeof file.name) {
      let fileExtension = '';
      if (file?.type?.indexOf('image/') !== -1) {
        fileExtension = 'png';
      }
      if (file?.type?.indexOf('video/') !== -1) {
        fileExtension = 'mp4';
      }
      if (file?.type?.indexOf('audio/') !== -1) {
        fileExtension = 'mp3';
      }

      audioFile = await new File([file], new Date().getTime() + '.' + fileExtension, {
        type: file.type.split(';')?.[0],
      });
    }

    return audioFile;
  },
});

export default actionsAsync;
