import PropTypes from 'prop-types';

import './Spinner.scss';

const Spinner = ({ size = 'medium', color = 'primary' }) => {
  return (
    <div className='vs-spinner__wrapper'>
      <div className={`vs-spinner__container vs-spinner--${size} vs-spinner--${color}`}>
        {Array.from({ length: 8 }).map((_, i) => (
          <div key={i} />
        ))}
      </div>
    </div>
  );
};

Spinner.propTypes = {
  size: PropTypes.oneOf(['medium', 'large', 'small']),
  color: PropTypes.oneOf(['primary', 'secondary', 'accent']),
};

export default Spinner;
