const appTemplates = {
  default: {
    slug: 'default',
    name: 'VideoStamps',
    logoImageUrl: '/images/vs-logo-dark.svg',
    cssVars: {},
    senderLandingVideoIcon: true,
    senderPoweredByVs: true,
    poweredByVsLocation: 'center',
    senderPoweredByVsLocation: 'bottom',
    headerLogoOnlyOnSenderLanding: false,
    welcomeMessage: 'Activate your VideoStamp and send a personalized message on any occasion.',
    welcomeMessagePhotoAndVideo: 'Record or upload a photo or video of the loved one to accompany your sympathy card',
    welcomeImageUrl: '/images/vs-two-stamps-v2.png',
    ctaMessage: 'Enjoy 10% off your next purchase with our exclusive promo code CODE10OFF.',
    ctaBtnText: 'Shop Now',
    ctaBtnUrl: process.env.SHOP_NOW_URL,
    ctaOnSenderThankyou: true,
    ctaOnRecipientLanding: false,
    ctaOnRecipientThankyou: true,
    shareSocialSender: true,
    shareSocialRecipient: true,
    shareMessage: "Don't keep the fun to yourself.",
    shareBtnText: 'Share With Your Friends',
    recipientLandingMessage: 'You have a video message',
    recipientLandingPhotoMessage: 'You have a photo message',
    replyFeatureEnabled: true,
    downloadBtn: true,
    senderSuccessMediaMessage: 'Thank you for your sympathy photo message',
    senderSuccessMessage: 'That was easy!',
    senderAttachedMediaMessage: 'Your photo has been successfully attached.',
    senderAttachedMessage: 'Your video has been successfully attached.',
    senderThankyouMessage: 'Confirmed!',
    senderThankyouMessage2: 'Your video has been successfully attached.',
    senderOptionsIcon: 'finger-snap',
    replySuccessMessage: 'That was easy!',
    type: 'VIDEO',
  },
  target: {
    slug: 'target',
    name: 'Target',
    logoImageUrl: '/images/brand/target-logo.svg',
    cssVars: {
      '--color-brand-secondary': '#ec1c2e',
      '--color-brand-secondary-dark': '#be1523',
      '--color-brand-secondary-light': '#f8a6ad',
      '--header-logo-height': '48px',
    },
    senderLandingVideoIcon: true,
    senderPoweredByVs: true,
    poweredByVsLocation: 'center',
    senderPoweredByVsLocation: 'bottom',
    headerLogoOnlyOnSenderLanding: false,
    welcomeMessage: 'Activate your VideoStamp and send a personalized message on any occasion.',
    welcomeImageUrl: '/images/vs-two-stamps-v2.png',
    ctaMessage: 'Enjoy 10% off your next purchase with our exclusive promo code CODE10OFF.',
    ctaBtnText: 'Shop Target',
    ctaBtnUrl: 'https://www.target.com',
    ctaOnSenderThankyou: true,
    ctaOnRecipientLanding: false,
    ctaOnRecipientThankyou: true,
    shareSocialSender: true,
    shareSocialRecipient: true,
    shareMessage: "Don't keep the fun to yourself.",
    shareBtnText: 'Share With Your Friends',
    recipientLandingMessage: 'You have a video message',
    replyFeatureEnabled: true,
    downloadBtn: true,
    senderSuccessMessage: 'That was easy!',
    senderAttachedMessage: 'Your video has been successfully attached.',
    senderThankyouMessage: 'Confirmed!',
    senderThankyouMessage2: 'Your video has been successfully attached.',
    senderOptionsIcon: 'finger-snap',
    replySuccessMessage: 'That was easy!',
  },
  chla: {
    slug: 'chla',
    name: 'Childrens Hospital Los Angeles',
    logoImageUrl: '/images/brand/chla-logo.svg',
    cssVars: {
      '--color-brand-secondary': '#004877',
      '--color-brand-secondary-dark': '#002842',
      '--color-brand-secondary-light': '#80a3bb',
      '--color-brand-cta': ' #ff7f00',
      '--color-brand-cta-dark': '#cc6600',
      '--color-brand-cta-light': '#ffa64d',
      '--header-logo-height': '48px',
      '--header-sender-landing-logo-height': '64px',
    },
    senderLandingVideoIcon: true,
    senderPoweredByVs: true,
    poweredByVsLocation: 'center',
    senderPoweredByVsLocation: 'bottom',
    headerLogoOnlyOnSenderLanding: false,
    welcomeMessage: 'Activate your VideoStamp and send a personalized message on any occasion.',
    welcomeImageUrl: '/images/brand/chla-welcome.png',
    ctaMessage: null,
    ctaBtnText: 'Donate to CHLA',
    ctaBtnUrl:
      'https://secure1.chla.org/site/Donation2;jsessionid=00000000.app20117a?df_id=2241&mfc_pref=T&2241.donation=form1&NONCE_TOKEN=218A6F87FC3800EA139D15668FC31D28%C2%A0',
    ctaOnSenderThankyou: true,
    ctaOnRecipientLanding: false,
    ctaOnRecipientThankyou: true,
    shareSocialSender: true,
    shareSocialRecipient: false,
    shareMessage: null,
    shareBtnText: 'Share With Your Friends',
    recipientLandingMessage: 'You have a video message',
    replyFeatureEnabled: true,
    downloadBtn: true,
    senderSuccessMessage: 'That was easy!',
    senderAttachedMessage: 'Your video has been successfully attached.',
    senderThankyouMessage: 'Confirmed!',
    senderThankyouMessage2: 'Your video has been successfully attached.',
    senderOptionsIcon: 'finger-snap',
    replySuccessMessage: 'That was easy!',
  },
  erewhon: {
    slug: 'erewhon',
    name: 'Erewhon',
    logoImageUrl: '/images/brand/erewhon-logo.svg',
    cssVars: {
      '--color-brand-secondary': '#000000',
      '--color-brand-secondary-dark': '#808080',
      '--color-brand-secondary-light': '#ffffff',
      '--header-logo-height': '46px',
    },
    senderLandingVideoIcon: true,
    senderPoweredByVs: true,
    poweredByVsLocation: 'center',
    senderPoweredByVsLocation: 'bottom',
    headerLogoOnlyOnSenderLanding: false,
    welcomeMessage: 'Activate your VideoStamp and send a personalized message on any occasion.',
    welcomeImageUrl: '/images/brand/erewhon-welcome.png',
    ctaBtnText: 'Shop Erewhon Market',
    ctaBtnUrl: 'https://www.erewhonmarket.com/',
    ctaOnSenderThankyou: true,
    ctaOnRecipientLanding: false,
    ctaOnRecipientThankyou: true,
    shareSocialSender: false,
    shareSocialRecipient: false,
    shareMessage: null,
    shareBtnText: null,
    recipientLandingMessage: 'You have a video message',
    replyFeatureEnabled: true,
    downloadBtn: true,
    senderSuccessMessage: 'That was easy!',
    senderAttachedMessage: 'Your video has been successfully attached.',
    senderThankyouMessage: 'Confirmed!',
    senderThankyouMessage2: 'Your video has been successfully attached.',
    senderOptionsIcon: 'mesg-check',
    replySuccessMessage: 'That was easy!',
  },
  farmgirl: {
    slug: 'farmgirl',
    name: 'Farm Girl Flowers',
    logoImageUrl: '/images/brand/farmgirl-logo.png',
    cssVars: {
      '--color-brand-secondary': '#ff2fa1',
      '--color-brand-secondary-dark': '#fe2fa4',
      '--color-brand-secondary-light': '#FF97D0',
      '--color-brand-cta': '#ff2fa1',
      '--color-brand-cta-dark': '#fe2fa4',
      '--color-brand-cta-light': '#FF97D0',
      '--header-logo-height': '73px',
    },
    senderLandingVideoIcon: true,
    senderPoweredByVs: true,
    poweredByVsLocation: 'center',
    senderPoweredByVsLocation: 'bottom',
    headerLogoOnlyOnSenderLanding: false,
    welcomeMessage: 'Activate your VideoStamp and send a personalized message on any occasion.',
    welcomeImageUrl: '/images/brand/farmgirl-welcome.png',
    ctaBtnText: 'Shop Farmgirl Flowers',
    ctaBtnUrl: 'https://farmgirlflowers.com/',
    ctaOnSenderThankyou: true,
    ctaOnRecipientLanding: false,
    ctaOnRecipientThankyou: true,
    shareSocialSender: false,
    shareSocialRecipient: false,
    shareMessage: null,
    shareBtnText: null,
    recipientLandingMessage: 'You have a video message',
    replyFeatureEnabled: true,
    downloadBtn: true,
    senderSuccessMessage: 'That was easy!',
    senderAttachedMessage: 'Your video has been successfully attached.',
    senderThankyouMessage: 'Confirmed!',
    senderThankyouMessage2: 'Your video has been successfully attached.',
    senderOptionsIcon: 'mesg-check',
    replySuccessMessage: 'That was easy!',
  },
  'chandler-v1': {
    slug: 'chandler',
    name: 'Chandler Funeral Home',
    logoImageUrl: '/images/brand/chandler-logo.png',
    cssVars: {
      '--color-brand-secondary': ' #680c04',
      '--color-brand-secondary-dark': '#360602',
      '--color-brand-secondary-light': '#635452',
      '--color-brand-cta': ' #846e42',
      '--color-brand-cta-dark': '#725b2c',
      '--color-brand-cta-light': '#a8946a',
      '--header-logo-height': '96px',
      '--header-recipient-landing-logo-height': '80px',
      '--header-recipient-landing-logo-mb': '0.5rem',
      '--font-family': 'quattrocento, serif',
    },
    senderLandingVideoIcon: true,
    senderPoweredByVs: true,
    poweredByVsLocation: 'center',
    senderPoweredByVsLocation: 'bottom',
    headerLogoOnlyOnSenderLanding: false,
    welcomeMessage: 'Record or upload your video to activate your VideoStamp.',
    welcomeImageUrl: '/images/brand/chandler-welcome.png',
    ctaMessage: null,
    ctaBtnText: 'Order Keepsake',
    ctaBtnUrl: 'https://tributesuite.com/orders/85692668273490453558',
    ctaOnSenderThankyou: true,
    ctaOnRecipientLanding: true,
    ctaOnRecipientThankyou: true,
    shareSocialSender: false,
    shareSocialRecipient: false,
    shareMessage: null,
    shareBtnText: null,
    recipientLandingMessage: 'Preview Your Tribute Video',
    replyFeatureEnabled: false,
    downloadBtn: false,
    senderSuccessMessage: 'Your sympathy message is on its way.',
    senderAttachedMessage: 'Your video has been successfully attached.',
    senderThankyouMessage: 'Your sympathy message is on its way.',
    senderThankyouMessage2: 'Your video has been successfully attached.',
    senderOptionsIcon: 'finger-snap',
    replySuccessMessage: 'Your sympathy message \n is on its way.',
  },
  'chandler-v2': {
    slug: 'chandler',
    name: 'Chandler Funeral Home',
    logoImageUrl: '/images/brand/chandler-logo.png',
    cssVars: {
      '--color-brand-secondary': ' #680c04',
      '--color-brand-secondary-dark': '#360602',
      '--color-brand-secondary-light': '#635452',
      '--color-brand-cta': ' #846e42',
      '--color-brand-cta-dark': '#725b2c',
      '--color-brand-cta-light': '#a8946a',
      '--header-logo-height': '96px',
      '--header-recipient-landing-logo-height': '80px',
      '--header-recipient-landing-logo-mb': '0.5rem',
      '--font-family': 'quattrocento, serif',
    },
    senderLandingVideoIcon: true,
    senderPoweredByVs: true,
    poweredByVsLocation: 'center',
    senderPoweredByVsLocation: 'bottom',
    headerLogoOnlyOnSenderLanding: false,
    welcomeMessage: 'Record or upload your video to activate your VideoStamp.',
    welcomeImageUrl: '/images/brand/chandler-welcome.png',
    ctaMessage: null,
    ctaBtnText: 'Order Keepsake',
    ctaBtnUrl: 'https://tributesuite.com/orders/85692668273490453558',
    ctaOnSenderThankyou: true,
    ctaOnRecipientLanding: true,
    ctaOnRecipientThankyou: true,
    shareSocialSender: false,
    shareSocialRecipient: false,
    shareMessage: null,
    shareBtnText: null,
    recipientLandingMessage: 'Preview Your Tribute Video',
    replyFeatureEnabled: true,
    downloadBtn: false,
    senderSuccessMessage: 'Your sympathy message is on its way.',
    senderAttachedMessage: 'Your video has been successfully attached.',
    senderThankyouMessage: 'Your sympathy message is on its way.',
    senderThankyouMessage2: 'Your video has been successfully attached.',
    senderOptionsIcon: 'finger-snap',
    replySuccessMessage: 'Your sympathy message \n is on its way.',
  },
  tribute: {
    slug: 'tribute',
    name: 'Tribute',
    logoImageUrl: '/images/brand/tribute-sympathy-cards-logo.png',
    cssVars: {
      '--color-brand-secondary': ' #142E48',
      '--color-brand-secondary-dark': '#0b1a28',
      '--color-brand-secondary-light': '#80A3BB',
      '--header-logo-height': '42px',
      '--header-sender-landing-logo-margin': '58px 32px 8px',
    },
    senderLandingVideoIcon: false,
    senderPoweredByVs: true,
    poweredByVsLocation: 'bottom',
    senderPoweredByVsLocation: 'top',
    headerLogoOnlyOnSenderLanding: true,
    welcomeMessage: 'Record or upload your \n personalized sympathy \n video message',
    welcomeMessagePhotoAndVideo: 'Record or upload a photo or video of the loved one to accompany your sympathy card',
    welcomeMessagePhoto: 'Record or upload your \n personalized sympathy \n photo message',
    welcomeImageUrl: '/images/brand/tribute-welcome.png',
    ctaMessage: null,
    ctaBtnText: 'Order Keepsake',
    ctaBtnUrl: 'https://tributesuite.com/orders/85692668273490453558',
    ctaOnSenderThankyou: false,
    ctaOnRecipientLanding: false,
    ctaOnRecipientThankyou: false,
    shareSocialSender: false,
    shareSocialRecipient: false,
    shareMessage: null,
    shareBtnText: null,
    recipientLandingMessage: 'You have a video message',
    replyFeatureEnabled: true,
    downloadBtn: true,
    senderSuccessMediaMessage: 'Thank you for your sympathy photo message',
    senderAttachedMediaMessage: 'Your photo has been successfully attached.',
    senderSuccessMessage: 'Thank you for your \n sympathy video message',
    senderAttachedMessage: 'Your video has been successfully attached.',
    senderThankyouMessage: 'Your card with your sympathy video  message  is on its way.',
    senderThankyouMessage2: 'We will mail it, thank you.',
    replySuccessMessage: 'Your reply has been sent.',
    senderOptionsIcon: 'mesg-check',
  },
  jadore: {
    slug: 'jadore',
    name: 'Jadore',
    logoImageUrl: '/images/brand/jadore-logo.png',
    cssVars: {
      '--color-brand-secondary': '#000000',
      '--color-brand-secondary-dark': '#808080',
      '--color-brand-secondary-light': '#b5b1b2',
      '--header-logo-height': '149px',
    },
    senderLandingVideoIcon: true,
    senderPoweredByVs: true,
    poweredByVsLocation: 'center',
    headerLogoOnlyOnSenderLanding: false,
    welcomeMessage: 'Activate your VideoStamp and send a personalized message on any occasion.',
    welcomeImageUrl: '/images/brand/jadore-welcome.png',
    ctaBtnText: "Shop J'Adore Les Fleurs",
    ctaBtnUrl: 'https://www.jadorelesfleurs.com/',
    ctaOnSenderThankyou: true,
    ctaOnRecipientLanding: false,
    ctaOnRecipientThankyou: true,
    shareSocialSender: true,
    shareSocialRecipient: true,
    recipientLandingMessage: 'You have a video message',
    replyFeatureEnabled: true,
    downloadBtn: true,
    senderSuccessMessage: 'That was easy!',
    senderAttachedMessage: 'Your video has been successfully attached.',
    senderThankyouMessage: 'Confirmed!',
    senderThankyouMessage2: 'Your video has been successfully attached.',
    senderOptionsIcon: 'mesg-check',
    replySuccessMessage: 'That was easy!',
  },
  'urban-stems': {
    slug: 'urban-stems',
    name: 'UrbanStems',
    logoImageUrl: '/images/brand/urban-stems-logo.png',
    cssVars: {
      '--color-brand-secondary': '#263A53',
      '--color-brand-secondary-dark': '#929CA9',
      '--color-brand-secondary-light': '#adb8c7',
      '--header-logo-height': '48px',
    },
    senderLandingVideoIcon: true,
    senderPoweredByVs: true,
    poweredByVsLocation: 'center',
    headerLogoOnlyOnSenderLanding: false,
    welcomeMessage: 'Activate your VideoStamp and send a personalized message on any occasion.',
    welcomeImageUrl: '/images/brand/urban-stems-welcome.png',
    ctaBtnText: 'Shop UrbanStems',
    ctaBtnUrl: 'https://urbanstems.com',
    ctaOnSenderThankyou: true,
    ctaOnRecipientLanding: false,
    ctaOnRecipientThankyou: true,
    recipientLandingMessage: 'You have a video message',
    replyFeatureEnabled: true,
    downloadBtn: true,
    senderSuccessMessage: 'That was easy!',
    senderAttachedMessage: 'Your video has been successfully attached.',
    senderThankyouMessage: 'Confirmed!',
    senderThankyouMessage2: 'Your video has been successfully attached.',
    senderOptionsIcon: 'mesg-check',
    replySuccessMessage: 'That was easy!',
  },
  'venus-fleur': {
    slug: 'venus-fleur',
    name: 'VenusEtFleur',
    logoImageUrl: '/images/brand/venus-logo.png',
    cssVars: {
      '--color-brand-secondary': '#8D5078',
      '--color-brand-secondary-dark': '#C6A7BB',
      '--color-brand-secondary-light': '#C7B7C1',
      '--header-logo-height': '133px',
    },
    senderLandingVideoIcon: true,
    senderPoweredByVs: true,
    poweredByVsLocation: 'center',
    headerLogoOnlyOnSenderLanding: false,
    welcomeMessage: 'Activate your VideoStamp and send a personalized message on any occasion.',
    welcomeImageUrl: '/images/brand/venus-welcome.png',
    ctaBtnText: 'Shop Venus et Fleur',
    ctaBtnUrl: 'https://www.venusetfleur.com',
    ctaOnSenderThankyou: true,
    ctaOnRecipientLanding: false,
    ctaOnRecipientThankyou: true,
    recipientLandingMessage: 'You have a video message',
    replyFeatureEnabled: true,
    downloadBtn: true,
    senderSuccessMessage: 'That was easy!',
    senderAttachedMessage: 'Your video has been successfully attached.',
    senderThankyouMessage: 'Confirmed!',
    senderThankyouMessage2: 'Your video has been successfully attached.',
    senderOptionsIcon: 'mesg-check',
    replySuccessMessage: 'That was easy!',
  },
  compartes: {
    slug: 'compartes',
    name: 'Compartes',
    logoImageUrl: '/images/brand/compartes-logo.png',
    cssVars: {
      '--color-brand-secondary': '#0097D8',
      '--color-brand-secondary-dark': '#80CBEB',
      '--color-brand-secondary-light': '#a6daf1',
      '--header-logo-height': '63px',
    },
    senderLandingVideoIcon: true,
    senderPoweredByVs: true,
    poweredByVsLocation: 'center',
    headerLogoOnlyOnSenderLanding: false,
    welcomeMessage: 'Activate your VideoStamp and send a personalized message on any occasion.',
    welcomeImageUrl: '/images/brand/compartes-welcome.png',
    ctaBtnText: 'Shop Compartes',
    ctaBtnUrl: 'https://compartes.com',
    ctaOnSenderThankyou: true,
    ctaOnRecipientLanding: false,
    ctaOnRecipientThankyou: true,
    recipientLandingMessage: 'You have a video message',
    replyFeatureEnabled: true,
    downloadBtn: true,
    senderSuccessMessage: 'That was easy!',
    senderAttachedMessage: 'Your video has been successfully attached.',
    senderThankyouMessage: 'Confirmed!',
    senderThankyouMessage2: 'Your video has been successfully attached.',
    senderOptionsIcon: 'mesg-check',
    replySuccessMessage: 'That was easy!',
  },
  bunda: {
    slug: 'bunda',
    name: 'Bunda',
    logoImageUrl: '/images/brand/bunda-logo.png',
    cssVars: {
      '--color-brand-secondary': '#FF9E8F',
      '--color-brand-secondary-dark': '#FF9E8F',
      '--color-brand-secondary-light': '#FFFFFF',
      '--header-logo-height': '46px',
    },
    senderLandingVideoIcon: true,
    senderPoweredByVs: true,
    poweredByVsLocation: 'center',
    senderPoweredByVsLocation: 'bottom',
    headerLogoOnlyOnSenderLanding: false,
    welcomeMessage: 'Activate your VideoStamp and send a personalized message on any occasion.',
    welcomeImageUrl: '/images/brand/bunda-welcome.png',
    welcomeIframeUrl:
      'https://www.youtube.com/embed/Vf1690J7SRI?autoplay=1&mute=1&enablejsapi=1&loop=1&playlist=Vf1690J7SRI&rel=0',
    welcomeIframeTitle: 'Bunda YouTube video player',
    ctaBtnText: 'Book a Class',
    ctaBtnUrl: 'https://www.trainbunda.com/',
    ctaOnSenderThankyou: true,
    ctaOnRecipientLanding: false,
    ctaOnRecipientThankyou: true,
    shareSocialSender: false,
    shareSocialRecipient: false,
    shareMessage: null,
    shareBtnText: null,
    recipientLandingMessage: 'You have a video message',
    replyFeatureEnabled: true,
    downloadBtn: true,
    senderSuccessMessage: 'That was easy!',
    senderAttachedMessage: 'Your video has been successfully attached.',
    senderThankyouMessage: 'Confirmed!',
    senderThankyouMessage2: 'Your video has been successfully attached.',
    senderOptionsIcon: 'mesg-check',
    replySuccessMessage: 'That was easy!',
  },
};

export default appTemplates;
