import { useLocation } from 'react-router-dom';
import { ROUTES } from '@src/constants';

import { useStore } from '@src/redux/store-zustand';

import './LogoHeader.scss';

const LogoHeader = () => {
  const { appTemplate } = useStore();
  const location = useLocation();

  //inline styles for app templates
  let stylesAppTemplate = {};
  const heightSenderLanding = appTemplate.cssVars?.['--header-sender-landing-logo-height'];
  const marginSenderLanding = appTemplate.cssVars?.['--header-sender-landing-logo-margin'];
  const heightRecipientLanding = appTemplate.cssVars?.['--header-recipient-landing-logo-height'];
  const mbRecipientLanding = appTemplate.cssVars?.['--header-recipient-landing-logo-mb'];
  let displayLogo = !appTemplate?.headerLogoOnlyOnSenderLanding;
  if (location.pathname === ROUTES.senderLanding) {
    heightSenderLanding && (stylesAppTemplate.height = heightSenderLanding);
    marginSenderLanding && (stylesAppTemplate.margin = marginSenderLanding);
    appTemplate?.headerLogoOnlyOnSenderLanding && (displayLogo = true);
  }
  if (location.pathname === ROUTES.recipientLanding) {
    mbRecipientLanding && (stylesAppTemplate.marginBottom = mbRecipientLanding);
    heightRecipientLanding && (stylesAppTemplate.height = heightRecipientLanding);
  }

  //don't display logo on some routes
  if (location.pathname === ROUTES.homepage || location.pathname === '/' || location.pathname === ROUTES.uploadShopify)
    return null;

  return (
    <header role='banner' className='flex flex-col justify-center flex-shrink-0 vs-header'>
      {displayLogo && (
        <img
          src={appTemplate?.logoImageUrl}
          alt={`${appTemplate?.name || 'videostamps'} logo`}
          style={stylesAppTemplate}
        />
      )}
    </header>
  );
};

export default LogoHeader;
