const config = {
  //desktop mediaRecorder only
  audioBitrate: 128000, // 128kb/s
  videoBitrate: 3000000, // 3 mb/s
  timerUpdateInterval: 1000, // 1 sec
  //desktop and mobile camera preview and desktop mediaRecorder
  videoWidth: undefined, // auto
  videoHeight: { max: 1920 }, // px
  frameRate: { ideal: 30, max: 60 }, // fps
  //desktop & mobile
  maxVideoRecordingDuration: 3 * 60, // sec
  maxVideoReplyRecordingDuration: 3 * 60, // sec
  maxVideoRecordingFileSize: 200, // MB
  maxReplyVideoRecordingFileSize: 200, // MB
  maxReplyMessageLength: 140, // chars

  refreshTokenCookieExpiresIn: 1000 * 3600 * 24 * 365, // 1 year in miliseconds

  socialShare: {
    fb: {
      title: 'Video by {CompanyName}', //company name replaced with regex
      description: 'Stick a personal video message to just about anything!',
    },
    twt: {
      title: 'Video by {CompanyName}', //company name replaced with regex
    },
  },
};

export default config;
