import React from 'react';
import ReactDOM from 'react-dom';
import { MemoryRouter } from 'react-router-dom';

import App from './App';

import '@styles/index.scss';

//load intial entries from url in memory router and replace url with /
const initialEntries = [document.location.pathname + document.location.search];
if (process.env.BUILD_MODE === 'production' && window.history) {
  window.history.replaceState({}, '', '/');
}

//disable undo screen rotate for homepage
if (initialEntries[0] !== '/') {
  function undoScreenRotate(width, height) {
    const root = document.getElementById('rot-root');
    switch (window.orientation) {
      case 0:
        root.style.width = '100%';
        root.style.height = '100%';
        root.style.webkitTransform = 'rotate(0deg)';
        break;
      case -90:
        root.style.webkitTransformOrigin = `${height / 2}px ${height / 2}px`;
        root.style.webkitTransform = 'rotate(90deg)';
        root.style.width = width + 'px';
        root.style.height = height + 'px';
        break;
      case 90:
        root.style.webkitTransformOrigin = `${width / 2}px ${width / 2}px`;
        root.style.webkitTransform = 'rotate(-90deg)';
        root.style.width = width + 'px';
        root.style.height = height + 'px';
        break;
      case 180:
        root.style.webkitTransformOrigin = `${height / 2}px ${width / 2}px`;
        root.style.webkitTransform = 'rotate(180deg)';
        root.style.width = height + 'px';
        root.style.height = width + 'px';
        break;
      default:
        break;
    }
  }
  // tries to lock screen to portrait
  window.addEventListener('orientationchange', function () {
    //wait for dom to update
    setTimeout(() => {
      const height = window.innerWidth;
      const width = window.innerHeight;
      undoScreenRotate(width, height);
    }, 500);
  });

  const initialWidth = Math.abs(window.orientation) === 90 ? window.innerHeight : window.innerWidth;
  const initialHeight = Math.abs(window.orientation) === 90 ? window.innerWidth : window.innerHeight;
  undoScreenRotate(initialWidth, initialHeight);

  //recalc rot-root dimensions on user default font-size change / body
  const resizeObserver = new ResizeObserver(entries => {
    for (let entry of entries) {
      if (entry.target) {
        //wait for dom to update
        setTimeout(() => {
          const height = window.innerWidth;
          const width = window.innerHeight;
          undoScreenRotate(width, height);
        }, 100);
      }
    }
  });
  resizeObserver.observe(document.body);
}

ReactDOM.render(
  <MemoryRouter initialEntries={initialEntries}>
    <App />
  </MemoryRouter>,
  document.getElementById('root')
);
