import { useLayoutEffect, useState, useRef } from 'react';
import { createPortal } from 'react-dom';

import { ReactComponent as IconClose } from '@assets/svg/icon-close.svg';
import useOnClickOutside from '@src/hooks/useOnClickOutside';

import './Modal.scss';

function ReactPortal({ children, portalId = 'portal-modal' }) {
  const [portalElement, setPortalElement] = useState(null);

  //changes dom layout synchronously
  useLayoutEffect(() => {
    let element = document.getElementById(portalId);
    const parent = document.getElementById('root');
    let created = false;
    if (!portalElement) {
      element = document.createElement('div');
      element.setAttribute('id', portalId);
      parent.appendChild(element);
      created = true;
    }
    setPortalElement(element);

    return () => {
      created && parent.removeChild(element);
    };
  }, [portalId]);

  return portalElement ? createPortal(children, portalElement) : null;
}

function Modal({
  children,
  isOpen,
  handleClose = null,
  hasCloseBtnX = false,
  className = '',
  portalId = 'portal-modal-container',
}) {
  const containerRef = useRef(null);
  useOnClickOutside(containerRef, handleClose);

  if (!isOpen) return null;

  return (
    <ReactPortal portalId={portalId}>
      {isOpen && (
        <div className='modal-overlay z-fix-ios'>
          <div
            className={`modal-content z-fix-ios shadow-lg rounded-lg border-gray300 border-solid ${className}`}
            ref={containerRef}>
            {handleClose && hasCloseBtnX && (
              <button className='absolute top-0 right-0 m-4 text-brandPrimary text-2xl' onClick={handleClose}>
                <IconClose />
              </button>
            )}
            {children}
          </div>
        </div>
      )}
    </ReactPortal>
  );
}

export default Modal;
