import axios from 'axios';

export class MultiPartUploader {
  constructor(options) {
    if (!Object.keys(options).length) {
      const errorMessage = 'MultiPartUploader.constructor(): options object is empty!';

      console.error(errorMessage);
      throw new Error(errorMessage);
    }
    this.validationCode = this.blob = options.blob;
    this.fileName = options.fileName;
    this.file = new File([this.blob], this.fileName, { type: this.blob.type });
    this.uploadURLs = options.uploadURLs;
    this.abortController = options.abortController;
    this.initPayload = options.initPayload;
    // 'chunkSize' defaults to 5MB even if given chunkSize is smaller than 5.
    this.chunkSize =
      (!options.chunkSize || (!isNaN(+options.chunkSize) && options.chunkSize < 5) ? 5 : options.chunkSize) *
      1024 *
      1024;
    this.numberOfThreads = Math.ceil(this.blob.size / this.chunkSize);
    this.onComplete = options.onComplete;
    this.actions = {
      complete: async () => {
        await axios
          .post(
            this.uploadURLs.complete,
            {
              securityKey: this.initResponse?.securityKey,
              uploadId: this.createResponse?.uploadId,
              uploadPath: this.createResponse?.uploadPath,
              validationCode: options.validationCode,
            },
            {
              signal: this.abortController.signal,
            }
          )
          .catch(error => {
            this.actions.abort(error);
          });

        this.onComplete &&
          this.onComplete({
            id: this.initResponse.id,
          });
      },
      abort: (error, errorMessage = 'There was an error.') => {
        console.error(errorMessage, error);

        axios.post(this.uploadURLs.abort, {
          securityKey: this.initResponse?.securityKey,
          uploadId: this.createResponse?.uploadId,
          uploadPath: this.createResponse?.uploadPath,
        });
      },
    };
  }

  start = () => {
    this.init();

    return this;
  };

  init = async () => {
    // Init
    const initPayload = {
      ...this.initPayload,
      filename: this.file.name,
      fileSize: this.file.size,
    };

    this.initResponse = (
      await axios.post(this.uploadURLs.init, initPayload, {
        signal: this.abortController.signal,
      })
    )?.data;

    // Create
    const createPayload = {
      securityKey: this.initResponse.securityKey,
      path: this.initResponse?.uploadPath,
      name: this.initResponse.filename,
      type: this.file.type,
      size: this.file.size,
    };

    this.createResponse = (
      await axios.post(this.uploadURLs.create, createPayload, {
        signal: this.abortController.signal,
      })
    )?.data;

    // Upload Parts
    const partsPromises = [];
    const blobs = [];
    const s3Promises = [];
    const partsCompleteCallback = parts => {
      parts
        ?.map(part => part?.data?.url)
        .forEach((url, index) => {
          s3Promises.push(
            axios.put(url, blobs[index].blob, {
              signal: this.abortController.signal,
            })
          );
        });

      Promise.all(s3Promises)
        .then(() => this.actions.complete())
        .catch(error => {
          this.actions.abort('There was an error while uploading the chunks to S3.', error);
        });
    };
    const partsErrorCallback = error => {
      this.actions.abort('There was an error while trying to generate the S3 parts URls.', error);
    };

    for (let i = 0; i < this.numberOfThreads; i++) {
      const start = i * this.chunkSize;
      const end = (i + 1) * this.chunkSize;
      const blob = i < this.numberOfThreads ? this.blob.slice(start, end) : this.blob.slice(start);

      blobs.push({
        partNumber: i + 1,
        blob,
      });

      partsPromises.push(
        axios.post(
          this.uploadURLs.part,
          {
            securityKey: this.initResponse?.securityKey,
            uploadId: this.createResponse?.uploadId,
            uploadPath: this.createResponse?.uploadPath,
            partNumber: i + 1,
            contentLength: blob.size,
          },
          {
            signal: this.abortController.signal,
          }
        )
      );
    }

    Promise.all(partsPromises).then(partsCompleteCallback).catch(partsErrorCallback);
  };

  get = () => {
    return {
      id: this.initResponse.id,
    };
  };
}
