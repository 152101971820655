import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';

import { COOKIE_KEYS, publicRoutesRegExp, ROUTES } from './constants';
import { useStore } from './redux/store-zustand';
import ModalError from '@components/misc/ModalError';
import LogoHeader from '@components/misc/LogoHeader';
import LoadingFallback from '@components/misc/LoadingFallback';

const RouteRoot = React.lazy(() => import('@components/RouteRoot'));
const RouteSenderLanding = React.lazy(() => import('@components/RouteSenderLanding'));
const RouteUploadPreview = React.lazy(() => import('@components/RouteUploadPreview'));
const RouteSenderOptionsThankyou = React.lazy(() => import('@components/RouteSenderOptionsThankyou'));
const SenderOptions = React.lazy(() => import('@components/RouteSenderOptionsThankyou/SenderOptions'));
const SenderThankyou = React.lazy(() => import('@components/RouteSenderOptionsThankyou/SenderThankyou'));
const RouteRecipientSecured = React.lazy(() => import('@components/RouteRecipientSecured'));
const RouteRecipientLanding = React.lazy(() => import('@components/RouteRecipientLanding'));
const RouteRecipientReply = React.lazy(() => import('@components/RouteRecipientReply'));
const RecipientReplyOptions = React.lazy(() => import('@components/RouteRecipientReply/RecipientReplyOptions'));
const RecipientReplyVideo = React.lazy(() => import('@components/RouteRecipientReply/RecipientReplyVideo'));
const RecipientReplyMessage = React.lazy(() => import('@components/RouteRecipientReply/RecipientReplyMessage'));
const RouteRecipientOptionsThankyou = React.lazy(() => import('@components/RouteRecipientOptionsThankyou'));
const RouteSenderPhone = React.lazy(() => import('@components/RouteSenderPhone'));
const RouteVideoProcessing = React.lazy(() => import('@components/RouteVideoProcessing'));
const RouteViewVideo = React.lazy(() => import('@components/RouteViewVideo'));
const RouteQrCodeNotFound = React.lazy(() => import('@components/RouteQrCodeNotFound'));
const RouteQrcodeUsed = React.lazy(() => import('@components/RouteQrcodeUsed'));
const RouteDesktop = React.lazy(() => import('@components/RouteDesktop'));
const RouteHomepage = React.lazy(() => import('@components/RouteHomepage'));
const RouteReset = React.lazy(() => import('@components/RouteReset'));
const Route404 = React.lazy(() => import('@components/Route404'));
const RouteUploadShopify = React.lazy(() => import('@components/RouteUploadShopify'));
const RouteSenderShopifyThankyou = React.lazy(() => import('@components/RouteSenderShopifyThankyou'));
const RouteDemo = React.lazy(() => import('@components/RouteDemo'));

function App() {
  const { setUserName, setPhone, appTemplate } = useStore();
  const location = useLocation();

  useEffect(() => {
    //load username and phone from cookie and set in store
    const token = Cookies.get(COOKIE_KEYS.bearerToken);
    if (token) {
      const { username } = jwtDecode(token);
      setUserName(username);
    }
    const phoneCookie = Cookies.get(COOKIE_KEYS.phone);
    if (phoneCookie) {
      setPhone(JSON.parse(phoneCookie));
    }

    //for local debug
    if (process.env.NODE_ENV === 'development') return;

    //redirect to homepage if not on public routes
    if (publicRoutesRegExp.findIndex(regexp => RegExp(regexp, 'g').test(location.pathname)) === -1) {
      window.history.replaceState({}, '', '/');
      window.location.reload();
    }
  }, []);

  //update css vars with brand values
  useEffect(() => {
    if (appTemplate?.slug && appTemplate?.cssVars) {
      let cssText = '';
      Object.entries(appTemplate.cssVars).forEach(([varName, varValue]) => {
        cssText += `  ${varName}: ${varValue}; \n`;
      });
      const cssStyle = document.createElement('style');
      cssStyle.textContent = `:root {\n${cssText}}`;
      document.head.appendChild(cssStyle);
    }
  }, [appTemplate]);

  return (
    <>
      {<LogoHeader />}
      <React.Suspense fallback={<LoadingFallback />}>
        <Routes>
          {/* Handles redirects to sender/recipient/other routes based on api call to post/get projects*/}
          <Route path='/' index element={<RouteRoot />} />
          {/* Homepage, separate from app */}
          <Route path={ROUTES.homepage} element={<RouteHomepage />} />
          {/* Sender routes */}
          <Route path={ROUTES.senderLanding} element={<RouteSenderLanding />} />
          <Route path={ROUTES.senderUploadPreview} element={<RouteUploadPreview />} />
          <Route path={ROUTES.senderOptionsThankyou.parent} element={<RouteSenderOptionsThankyou />}>
            <Route path={ROUTES.senderOptionsThankyou.parent} element={<SenderOptions />} />
            <Route path={ROUTES.senderOptionsThankyou.children.options} element={<SenderOptions />} />
            <Route path={ROUTES.senderOptionsThankyou.children.thankyou} element={<SenderThankyou />} />
          </Route>
          {/* Recipient routes */}
          <Route path={ROUTES.recipientSecured} element={<RouteRecipientSecured />} />
          <Route path={ROUTES.recipientLanding} element={<RouteRecipientLanding />} />
          <Route path={ROUTES.recipientReply.parent} element={<RouteRecipientReply />}>
            <Route path={ROUTES.recipientReply.parent} element={<RecipientReplyOptions />} />
            <Route path={ROUTES.recipientReply.children.options} element={<RecipientReplyOptions />} />
            <Route path={ROUTES.recipientReply.children.video} element={<RecipientReplyVideo />} />
            <Route path={ROUTES.recipientReply.children.message} element={<RecipientReplyMessage />} />
          </Route>
          <Route path={ROUTES.recipientUploadPreview} element={<RouteUploadPreview />} />
          <Route path={ROUTES.recipientOptionsThankyou} element={<RouteRecipientOptionsThankyou />} />
          {/* Submit sender phone */}
          <Route path={ROUTES.submitSenderPhone} element={<RouteSenderPhone />} />
          {/*No available mediaUrl, video still processing on server */}
          <Route path={ROUTES.videoProcessing} element={<RouteVideoProcessing />} />
          {/* View video from sent sms url and social share */}
          <Route path={ROUTES.viewVideo} element={<RouteViewVideo />} />
          {/* Status pages */}
          <Route path={ROUTES.qrCodeNotFound} element={<RouteQrCodeNotFound />} />
          <Route path={ROUTES.qrCodeUsed} element={<RouteQrcodeUsed />} />
          <Route path={ROUTES.desktop} element={<RouteDesktop />} />
          {/* Reset code route */}
          <Route path={ROUTES.reset} element={<RouteReset />} />
          {/* Shopify routes */}
          <Route path={ROUTES.uploadShopify} element={<RouteUploadShopify />} />
          <Route path={ROUTES.senderShopifyThankyou} element={<RouteSenderShopifyThankyou />} />
          {/* Demo page */}
          <Route path={ROUTES.demo} element={<RouteDemo />} />
          {/* 404 */}
          <Route path={'/*'} element={<Route404 />} />
        </Routes>
      </React.Suspense>
      <ModalError />
    </>
  );
}

export default App;
