import PropTypes from 'prop-types';

import './Button.scss';

const Button = ({
  variant = 'primary',
  icon = null,
  className,
  iconClassName = '',
  children,
  as = 'button',
  type = 'button',
  ...props
}) => {
  let buttonStyles = `vs-btn vs-btn-${variant}`;
  className && (buttonStyles += ` ${className}`);
  const Icon = icon;
  const BtnElement = as;

  return (
    <BtnElement className={buttonStyles} type={as === 'button' ? type : undefined} {...props}>
      <>
        {icon && <Icon className={`text-xl mr-2 mt-0.5 ${iconClassName}`} />}

        {children}
      </>
    </BtnElement>
  );
};

Button.propTypes = {
  icon: PropTypes.object,
  variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'cta']),
};

export default Button;
