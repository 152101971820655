import { useStore } from '@src/redux/store-zustand';
import Button from '@components/misc/Button';
import Modal from '@components/misc/Modal';

export default function ModalError() {
  const errorsApi = useStore(state => state.errors);
  const modalError = useStore(state => state.modalError);
  const setModalError = useStore(state => state.setModalError);

  const handleClose = () => {
    setModalError({ isOpen: false, component: null });
    modalError?.onClose?.();
  };

  let errorContent = 'There was an error, please try again later.';
  modalError?.component && (errorContent = modalError.component);
  errorsApi?.userMessage && (errorContent = errorsApi.userMessage);
  modalError?.userMessage && (errorContent = modalError.userMessage);

  return (
    <Modal isOpen={modalError.isOpen} handleClose={handleClose} portalId='portal-modal-error'>
      <p className='text-brandPrimary text-xl leading-6 text-center'>{errorContent}</p>
      <Button className='mt-10' variant='primary' onClick={handleClose}>
        Ok
      </Button>
    </Modal>
  );
}
